import { Pipe, PipeTransform, NgModule } from '@angular/core';

export enum EstadoEvaluacion {
  anulado = 0,
  en_proceso = 1,
  completado = 2
};
export const constante = {
  tipo_conducta: {
    'F': 'fuerza',
    'M': 'movilidad',
    'E': 'equilibrio',
  },
  estado_evaluacion: [
    'Anulado',
    'En proceso',
    'Completado'
  ],
  tobillo: {
    'I': 'Izquierdo',
    'D': 'Derecho',
  },
  genero: {
    'M': 'Masculino',
    'F': 'Femenino',
    'O': 'Otro',
  }
};
@Pipe({
  name: 'constante',
  pure: false
})
export class ConstantesPipe implements PipeTransform {
  transform(estado: any, tipo: string): any {
    if (!estado || !tipo || !constante[tipo] || !constante[tipo][estado]) {
      return null;//'valor invalido ' + JSON.stringify(estado) + ' para ' + JSON.stringify(tipo);
    }
    return constante[tipo][estado];
  }
}

// @NgModule({
//     imports: [
//         // dep modules
//     ],
//     declarations: [
//         ConstantesPipe
//     ],
//     exports: [
//         ConstantesPipe
//     ]
// })
// export class ConstantesPipeModule { }