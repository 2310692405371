import { Component } from '@angular/core';

import { Router } from '@angular/router';
import { AuthService } from 'src/services/api/auth.service';
import { DataService } from 'src/services/app/data.service';
//import { ResourceService } from './_services/api/resource.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.css']
})
export class AppComponent {
  constructor(
    private authService: AuthService,
    //private resourceService: ResourceService,
    private router: Router,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.authService.authState.subscribe(state => {
      console.log('initializeApp authService.authState', state, DataService.usuario);
      if (state) {
        this.actualizarDatosUsuario(DataService.usuario.id);
        console.log('ruta_inicio', DataService.ruta_inicio)
        this.router.navigate(...DataService.ruta_inicio);
      } else {
        DataService.usuario = null;
        this.router.navigate(['/ingresar']);
      }
    });
  }
  actualizarDatosUsuario(id) {
    this.authService.me().then(u => {
      DataService.usuario = { ...DataService.usuario, ...u };
    })
  }
}
