<mat-toolbar>
  <mat-toolbar-row class="container">
    <img class="mr-5 logo-menu" src="assets/img/logo.svg">
    <a mat-button class="nav-link d-flex align-items-center h-100" [routerLink]="['/admin/usuarios']"
      [routerLinkActive]="['active']">Usuarios</a>
    <a mat-button class="nav-link d-flex align-items-center h-100" [routerLink]="['/admin/contratos']"
      [routerLinkActive]="['active']">Contratos</a>
    <div class="ml-auto h-100">
      <!-- <a mat-button  [routerLink]="['/admin/alumnos']"  [routerLinkActive]="['active']">Alumnos</a>
          <a mat-button  [routerLink]="['/admin/profesores']" [routerLinkActive]="['active']">Profesores</a> -->
      <button mat-button [matMenuTriggerFor]="menu" class="d-flex align-items-center h-100">
        <div class="d-flex align-items-center h-100">
          <img class="img-user-menu" src="assets/img/user-default.jpg" style="margin-right: 10px;">
          <div class="info-admin mr-3">
            <div>{{usuario.persona.nombre}} {{usuario.persona.apellido}}</div>
            <!-- <div>Administrador</div> -->
          </div>
          <span class="material-icons">keyboard_arrow_down</span>
        </div>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="logout()">
          <span>Cerrar sesión</span>
        </button>
      </mat-menu>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<router-outlet></router-outlet>