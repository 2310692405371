import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import 'moment/locale/es';

@Pipe({ name: 'moment' })
export class MomentPipe implements PipeTransform {
  transform(value: Date | moment.Moment, dateFormat: string): any {
    if (!value) return null;
    moment.locale('es');
    return moment(value).tz('America/Santiago').format(dateFormat);
  }
}
@Pipe({ name: 'edad' })
export class EdadPipe implements PipeTransform {
  transform(value: Date): number {
    if (!value) return null;
    let today = moment();
    let birthdate = moment(value);
    let years = today.diff(birthdate, 'years');
    return years;
  }
}