import { RouterModule, Routes } from '@angular/router';
// import { Pag404Component } from './pag404/pag404.component';
// import { AppGuard } from './app.guard';
import { AdminModule } from './admin/admin.module';
// import { PublicModule } from './public/public.module';
import { IngresarModule } from './ingresar/ingresar.module';
import { AppGuard } from './app.guard';

const APP_ROUTES: Routes = [
  { path: '', redirectTo: '/ingresar', pathMatch: 'full' },
  { path: 'admin', loadChildren: () => AdminModule, canActivate: [AppGuard] },
  // { path: 'app', loadChildren:() => PublicModule},
  { path: 'ingresar', loadChildren: () => IngresarModule, },
  // { path: '', loadChildren:() => PromoModule},
  // {path: '', loadChildren: './promo/promo.module#PromoModule',},
  /* canActivate:[AppGuard]}, */
  // {path: 'app', loadChildren: './comunidad/comunidad.module#ComunidadModule',},

  // { path: '**',  component: Pag404Component },

];
export const routing = RouterModule.forRoot(APP_ROUTES, { useHash: false });
