import { Component, OnInit, Inject } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResourceService } from '../../../../services/api/resource.service';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms'; 
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-lista-usuarios',
  templateUrl: './lista-usuarios.component.html',
  styleUrls: ['./lista-usuarios.component.scss'],

})
export class ListaUsuariosComponent implements OnInit {
  
  loading = false;
  displayedColumns: string[] = ['nombre', 'fecha', 'correo', 'contrato', 'estado', 'acciones'];
  dataSource: any = null;
  filtroForm: FormGroup;
  url = 'usuario/paginar';
  contratos: any[] = [];
  selectedContrato: FormControl = new FormControl();
  contratosFiltrados: Observable<any[]>

  constructor(
    private router: Router,
    private resourceSrv: ResourceService,
    private snackBar: MatSnackBar, public dialog: MatDialog
  ) {
    this.load_form();
    this.load_contratos();
    this.load_data();
  }
  ngOnInit() { 
    this.contratosFiltrados = this.selectedContrato.valueChanges.pipe(
      startWith(''),
      map(value => this._filterContratos(value))
    );
  }

  load_form() {
    this.filtroForm = new FormBuilder().group({
      contrato: [''],
      start: [''],
      end: [''],
      estado: ['T'],
      por_pag: [10]
    });
  }

  async load_contratos() {
    const result = await this.resourceSrv.index('contrato');
    this.contratos = result;
    console.log('contratos', this.contratos);
  }

  async load_data() {
    this.loading = true;
    const params = { 'filtros': this.filtroForm.value};
    const result = await this.resourceSrv.post(this.url, params)
      .catch(err => { console.log(err); return { error: true, messagge: 'Ocurrio un error durante la carga'} });
    console.log(result);
    if (result.error) {
      this.snackBar.open(result.messagge, 'OK', { duration: 5000 });
    }
    else {
      this.dataSource = result;
    }
    this.loading = false;    
  }

  private _filterContratos(value: string): any[] {
    if (!value) {
      return [];
    }
    console.log('value', value);
    const filterValue = value.toLowerCase();
    return this.contratos.filter(contrato => contrato.nombre.toLowerCase().includes(filterValue));
  }

  clear(ifil) {
    switch (ifil) {
      case 1:
        this.filtroForm.controls.contrato.setValue('');
        this.selectedContrato.patchValue('');
        break;
      case 2:
        this.filtroForm.controls.start.setValue('');
        this.filtroForm.controls.end.setValue('');
        break;
      case 3:
        this.filtroForm.controls.estado.setValue('T');
        break;
    }
  }

  filtrar() {
    this.url = 'usuario/paginar';
    this.load_data();
  }

  cambiarPagina(page) {
    this.url = 'usuario/paginar?page=' + page.pageIndex;
    this.filtroForm.controls.por_pag.setValue(page.pageSize);
    this.load_data();
  }

  onContratoSelected(contrato: any): void {
    console.log('contrato', contrato);
    this.selectedContrato.patchValue(contrato.nombre);
    this.filtroForm.get('contrato')?.patchValue(contrato.id);
  }

  editar(usuario) {
    this.router.navigate(['/admin/usuarios/editar-usuario'], { state: { usuario } });
  }

  async cambiarEstado(usuario) {
    const result = await this.resourceSrv.get(`usuario/${usuario.id}/activar-desactivar`)
      .catch(err => { console.log(err); return { error: true, message: 'Ocurrio un error al cambiar el estado' } });
    this.snackBar.open(result.message, 'Ok', { duration: 3000 });
    if (!result.error) {
      usuario.estado = !usuario.estado;
    }
  }  

  async eliminar(usuario) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: { message: '¿Estás seguro de que deseas eliminar este usuario?' }
    });

    const response = await dialogRef.afterClosed().toPromise();
    if (response) {
      console.log('eliminar', usuario);
      const result = await this.resourceSrv.destroy('usuario', usuario.id)
        .catch(err => { console.log(err); return { error: true, message: 'Ocurrio un error al eliminar' } });
      this.snackBar.open(result.message, 'Ok', { duration: 3000 });
      if (!result.error) {
        this.filtrar();
      }
    }
  }

  verEvaluaciones(usuario) {
    let navigationExtras: NavigationExtras = {
      state: {
        usuario: usuario
      }
    };
    this.router.navigate(['admin/usuarios/evaluaciones-usuario'], navigationExtras);
  }

}

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-confirm-dialog',
  template: `
    <h1 mat-dialog-title>Confirmación</h1>
    <div mat-dialog-content>
      <p>{{data.message}}</p>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onNoClick()">Cancelar</button>
      <button mat-button [mat-dialog-close]="true">Aceptar</button>
    </div>
  `
})
export class ConfirmDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
