import { Pipe, PipeTransform, NgModule } from '@angular/core';
@Pipe({
    name: 'filter',
    pure: false
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], filter: (obj: any) => any): any {
        if (!items) {
            return []
        }
        return items.filter(filter);
    }
}

// @NgModule({
//     imports: [
//         // dep modules
//     ],
//     declarations: [
//         FilterPipe
//     ],
//     exports: [
//         FilterPipe
//     ]
// })
// export class FilterPipeModule { }