<div class="container-fluid container-login h-100">
  <div class="row h-100">
    <div class="col-5 box-login">
      <img class="mb-4" src="assets/img/logo-login.svg">
      <div *ngIf="!esOlvidado">
        <h1 class="mb-4">Iniciar sesión </h1>
        <form [formGroup]="ingresarForm" (submit)="ingresar()">
          <mat-form-field color="primary" appearance="outline" class="w-100">
            <mat-label>Email</mat-label>
            <input matInput placeholder="" formControlName="email" required autocomplete="off">
            <mat-error *ngIf="ingresarForm.controls.email.invalid">
              <!-- <mensajes-validacion [control]="ingresarForm.controls.email"></mensajes-validacion> -->
            </mat-error>
          </mat-form-field>
          <mat-form-field color="primary" appearance="outline" class="w-100">
            <mat-label>Contraseña</mat-label>
            <input matInput placeholder="" formControlName="password" [type]="type" required>
            <button *ngIf="type != 'text'" mat-button type="button" (click)="type = 'text'" matSuffix mat-icon-button>
              <mat-icon color="accent">visibility</mat-icon>
            </button>
            <button *ngIf="type == 'text'" mat-button type="button" (click)="type = 'password'" matSuffix
              mat-icon-button>
              <mat-icon color="accent">visibility_off</mat-icon>
            </button>
            <mat-error *ngIf="ingresarForm.controls.password.invalid">
              <!-- <mensajes-validacion [control]="ingresarForm.controls.contrasena"></mensajes-validacion> -->
            </mat-error>
          </mat-form-field>
          <div>
            <app-loading [loading]="loadingIngresar"></app-loading>
            <!-- [routerLink]="['/admin/cargos']"  -->
            <button class="button-ingresar w-100 mb-3" mat-flat-button type="submit" *ngIf="!loadingIngresar"
              [disabled]="!ingresarForm.valid || loadingIngresar" color="primary">Iniciar sesion</button>
            <a mat-button class="recuperar w-100" color="accent" (click)="esOlvidado = !esOlvidado">¿Olvidaste tu
              contraseña?</a>
            <!-- <button class="button-nueva" mat-stroked-button type="submit" *ngIf="!loadingIngresar" color="primary" >Crear cuenta</button> -->
            <!-- <button mat-flat-button  class="w-100" color="primary" [routerLink]="['/admin/usuarios']" >Entrar sin logear</button> -->
          </div>
        </form>
      </div>
      <!-- recuperar contraseña -->
      <form *ngIf="esOlvidado && !esCodigo">
        <div>
          <button mat-button class="mb-4" color="accent" (click)="esOlvidado = !esOlvidado">
            <span class="material-icons">keyboard_arrow_left</span> Volver a ingresar
          </button>
          <h1 class="mb-4">Recuperar contraseña</h1>
          <p class="info-box mb-4">Si confirmas, enviaremos un código de 4 dígitos a tu email.</p>
        </div>
        <form class="form-ingresar">
          <mat-form-field class="input-default w-100" color="primary" appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput placeholder="" [formControl]="email" required autocomplete="off">
          </mat-form-field>
        </form>
        <div>
          <button mat-flat-button class="btn-default w-100" type="submit" [disabled]="email.invalid"
            (click)="olvidado()" color="primary">Enviar código</button>
        </div>
      </form>
    </div>
    <div class="col-7 pr-0">
      <div class="img-login">
        <img src="assets/img/img-login.jpg">
      </div>
    </div>
  </div>
</div>
