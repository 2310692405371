<div class="top-content">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center">
          <h1>{{usuario ? 'Editar' : 'Agregar'}} usuario</h1>
          <div class="ml-auto breadcrumbs ">
            <a mat-button color="accent" [routerLink]="['/admin/usuarios']">
              Usuario
            </a>
            <span class="material-icons">keyboard_arrow_right</span>
            <a mat-button color="primary">
              {{usuario ? 'Editar' : 'Agregar'}} usuario
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container" style="margin-bottom:50px;">
  <form [formGroup]="usuarioForm" (submit)="guardar()" class="row flex-colunm pt-4">
    <!-- <app-loading [loading]="loading" [vistaLoading]="'normal'"></app-loading> -->
    <!-- tabla datos usuario -->
    <mat-form-field appearance="outline" class="col-12 col-md-4">
      <mat-label>Nombre de usuario</mat-label>
      <input formControlName="nombre" matInput>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-12 col-md-4">
      <mat-label>Correo</mat-label>
      <input formControlName="email" matInput>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-12 col-md-4">
      <mat-label>Contrato</mat-label>
      <input [value]="selectedContrato?.nombre" type="text" placeholder="Seleccionar" aria-label="Number" matInput [matAutocomplete]="auto" >
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onContratoSelected($event.option.value)">
        <mat-option *ngFor="let contrato of contratos" [value]="contrato">
          {{contrato.nombre}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div class="col-12">
      <button [disabled]="usuarioForm.invalid || loading" mat-flat-button color="primary">{{usuario ? 'Editar' : 'Agregar'}}</button>
    </div>
  </form>
</div>