import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  //public static _usuario: any = { token: null };
  /**
   *  Getter y Setter del Usuario:
   *  
   *  Hacen un atributo virtual, que se puede usar como DataService.usuario.
   *  No es necesario que exista un real atributo usuario porque lo obtenemos y seteamos directamente al localstorage.
   *  El Object.freeze(obj) nos asegura que cuidemos no modificar directamente atributos del DataService.usuario.
   *  Lo anterior debido a que, ¡modificar un atributo del objeto no llama al setter del objeto!
   *  Por ejemplo "DataService.usuario.atributo1 = 'ejemplo1'" no llamaría al setter, por lo que no se guardaría en el localstorage.
   *  Entonces para agregar o actualizar un atributo y que este se guarde automáticamente en el localstorage debería usarse:
   *   ->DataService.usuario = { ...DataService.usuario, atributo1:'ejemplo1', atributo2:'ejemplo2', etc:etc }
   *   ->o DataService.usuario = { ...DataService.usuario, ...datos_nuevos }
   *  Esta sintaxis une ambos objetos, actualizando los atributos del primero, y como esto sí llama al setter sí guarda el resultado en el localstorage.
   */
  static get usuario() {
    //return this._usuario;      
    var obj = this.get('usuario', { token: null, expires_in: null });
    Object.freeze(obj); // no modificar nada de esto sin avisar
    return obj;
  }
  static set usuario(data) {
    //this._usuario = data;
    this.set('usuario', data); // no modificar nada de esto sin avisar
    //console.log('usuario seteado:', DataService.usuario);
  }

  public static storagePrefix: string = "tobifix_";

  public static route_api: string = 'https://api-tobifix.elinous.cl/api/';
  // public static route_api: string='http://localhost:8000/api/';

  public static route_public: string = 'https://api-tobifix.elinous.cl/';
  // public static route_public: string='http://localhost:8000/';

  public static ruta_inicio: [any[], any] = [['admin/usuarios'], undefined];

  constructor() { }

  public static set(key: string, content: any) {
    localStorage.setItem(DataService.storagePrefix + key, JSON.stringify(content));
  }

  public static get(key: string, default_value: any = null) {
    var local = localStorage.getItem(DataService.storagePrefix + key) || null;
    return (local && local != 'undefined') ? JSON.parse(local) : default_value;
  }

  public static logUser(data, set_user = true) {
    console.log('%c⧭ logUser data', 'color: #99614d', data);
    var date = new Date();
    date.setMinutes(date.getMinutes() + data.expires_in);
    if (set_user) DataService.usuario = data.user;
    DataService.usuario = {
      ...DataService.usuario,
      token: data.access_token,
      expires_in: date.getTime()
    }
    console.log('data service log usuario', DataService.usuario);
  }

  public static getHeaders() {
    if (this.usuario.token != null && this.usuario.token != '') {
      return new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.usuario.token });
    }
    return new HttpHeaders({ 'Content-Type': 'application/json' });
  }
  public static getBlobHeaders() {
    if (this.usuario.token != null && this.usuario.token != '') {
      return new HttpHeaders({ "Accept": "application/json", 'Authorization': 'Bearer ' + this.usuario.token });
    }
    return new HttpHeaders({ "Accept": "application/json" });
  }
}
