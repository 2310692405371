import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable } from '@angular/material/table';
import { ResourceService } from '../../../../services/api/resource.service';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
// import { ConfirmDialogComponent } from '../lista-contratos/lista-contratos.component';

@Component({
  selector: 'app-lista-contratos',
  templateUrl: './lista-contratos.component.html',
  styleUrls: ['./lista-contratos.component.scss'],

})
export class ListaContratosComponent implements OnInit {
  loading = false;
  displayedColumns: string[] = ['nombre_contrato', 'razon_social', 'nombre_contacto', 'medios', 'num_usuarios', 'estado', 'acciones'];
  dataSource: any = null;

  filtroForm: FormGroup;
  url: string = 'contrato/listar';

  @ViewChild(MatTable) table: MatTable<any>;

  constructor(
    private router: Router,
    private resourceSrv: ResourceService,
    private snackBar: MatSnackBar, public dialog: MatDialog
  ) {
    this.filtroForm = new FormBuilder().group({
      contacto: '',
      correo: '',
      nombre: '',      
      estado: ['T'],
      por_pag: 10
    });
    this.listar();
  }

  ngOnInit() { }

  async listar() {
    this.loading = true;
    const result = await this.resourceSrv.post(this.url, { filtro: this.filtroForm.value })
      .catch(err => { console.log(err); return { error: true, message: 'Ocurrio un error en la carga' } });
    console.log('result', result)
    if (result.error) {
      this.snackBar.open(result.message, 'Ok', { duration: 3000 });
    }
    else {
      this.dataSource = result;
      this.table.renderRows();
    }
    this.loading = false;
  }

  buscar() {
    this.url = 'contrato/listar';
    this.listar();
  }

  cambiarPagina(e) {
    console.log('cambiarPagina', e);
    this.filtroForm.controls['por_pag'].setValue(e.pageSize);
    this.url = `contrato/listar?page=${e.pageIndex + 1}`;
    this.listar();
  }

  editar(contrato) {
    this.router.navigate(['/admin/contratos/editar-contrato'], { state: { contrato } });
  }

  async cambiarEstado(contrato) {
    console.log('cambiarEstado', contrato);
    const result = await this.resourceSrv.get(`contrato/${contrato.id}/activar-desactivar`)
      .catch(err => { console.log(err); return { error: true, message: 'Ocurrio un error al cambiar el estado' } });
    this.snackBar.open(result.message, 'Ok', { duration: 3000 });
    if (!result.error) {
      contrato.estado = !contrato.estado;
    }
  }

  async eliminar(contrato) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: { message: '¿Estás seguro de que deseas eliminar este contrato?' }
    });

    const response = await dialogRef.afterClosed().toPromise();
    if (response) {
      console.log('eliminar', contrato);
      const result = await this.resourceSrv.destroy('contrato', contrato.id)
        .catch(err => { console.log(err); return { error: true, message: 'Ocurrio un error al eliminar' } });
      this.snackBar.open(result.message, 'Ok', { duration: 3000 });
      if (!result.error) {
        this.buscar();
      }
    }
  }
}


import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  template: `
    <h1 mat-dialog-title>Confirmación</h1>
    <div mat-dialog-content>
      <p>{{data.message}}</p>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onNoClick()">Cancelar</button>
      <button mat-button [mat-dialog-close]="true">Aceptar</button>
    </div>
  `
})
export class ConfirmDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}