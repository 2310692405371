import { Injectable, Input } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EliPaginatorService implements MatPaginatorIntl {
  @Input() itemsPerPageLabel: string = 'Items por página';
  changes = new Subject<void>();
  //itemsPerPageLabel: string = 'Items por página';
  nextPageLabel: string;
  previousPageLabel: string;
  firstPageLabel: string;
  lastPageLabel: string;
  constructor() {
  }
  public setLabel(label) {
    this.itemsPerPageLabel = `${label} por página`;
    console.log('Changing paginator "itemsPerPageLabel" to this:', this);
    this.changes.next();
  }
  getRangeLabel = function (page, pageSize, length) {
    if (length === 0 || pageSize === 0) {
      return '0 de ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
  }
}
