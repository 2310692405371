import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { DataService } from "../app/data.service";

@Injectable()
export class ResourceService {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private api_url = DataService.route_api;

  constructor(private http: HttpClient) { }

  async index(resource_name, params: any = {}): Promise<any> {
    const url = this.api_url + 'resource/' + resource_name;
    try {
      const data = await this.http.get<any>(url, { params: params, headers: this.headers })
        .toPromise();
      return data as any;
    }
    catch (error) {
      return this.handleError(error);
    }
  }
  async store(resource_name, params: any = {}): Promise<any> {
    const url = this.api_url + 'resource/' + resource_name;
    try {
      const data = await this.http.post<any>(url, params, { headers: this.headers })
        .toPromise();
      return data as any;
    }
    catch (error) {
      return this.handleError(error);
    }
  }
  async show(resource_name, id: any, params: any = {}): Promise<any> {
    const url = this.api_url + 'resource/' + resource_name + '/' + id;
    try {
      const data = await this.http.get<any>(url, { params: params, headers: this.headers })
        .toPromise();
      return data as any;
    }
    catch (error) {
      return this.handleError(error);
    }
  }
  async update(resource_name, id: any, params: any = {}): Promise<any> {
    const url = this.api_url + 'resource/' + resource_name + '/' + id;
    try {
      const data = await this.http.put<any>(url, params, { headers: this.headers })
        .toPromise();
      return data as any;
    }
    catch (error) {
      return this.handleError(error);
    }
  }
  async destroy(resource_name, id: any, params: any = {}): Promise<any> {
    const url = this.api_url + 'resource/' + resource_name + '/' + id;
    try {
      const data = await this.http.delete<any>(url, { params: params, headers: this.headers })
        .toPromise();
      return data as any;
    }
    catch (error) {
      return this.handleError(error);
    }
  }
  async get(get_url, params: any = {}): Promise<any> {
    const url = this.api_url + get_url;
    try {
      const data = await this.http.get<any>(url, { params: params, headers: this.headers })
        .toPromise();
      return data as any;
    }
    catch (error) {
      return this.handleError(error);
    }
  }
  async post(post_url, params: any = {}): Promise<any> {
    const url = this.api_url + post_url;
    try {
      const data = await this.http.post<any>(url, params, { headers: this.headers })
        .toPromise();
      return data as any;
    }
    catch (error) {
      return this.handleError(error);
    }
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}
