<div class="top-content">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center">
          <h1>Evaluaciones Usuario</h1>
          <div class="ml-auto breadcrumbs ">
            <a mat-button color="accent" [routerLink]="['/admin/usuarios']">
              Usuario
            </a>
            <span class="material-icons">keyboard_arrow_right</span>
            <a mat-button color="primary">
              {{usuario[0]?.persona.nombre}} {{usuario[0]?.persona.apellido}} ({{usuario[0]?.email}})
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container" style="margin-bottom:50px;">
  <div class="row">
    <div class="col-12">
      <!-- <app-loading [loading]="loading" [vistaLoading]="'normal'"></app-loading> -->
      <!-- tabla datos usuario -->
      <table #tb1 class="table-default gray mt-4 mb-20" mat-table [dataSource]="usuario">
        <ng-container matColumnDef="img">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let usuario">
            <div class="img-user-table">
              <img src="{{usuario.persona?.url_img?usuario.persona.url_img:'assets/img/img-user.png'}}">
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>ID</th>
          <td mat-cell *matCellDef="let usuario">{{usuario.id}}</td>
        </ng-container>

        <ng-container matColumnDef="nombre">
          <th mat-header-cell *matHeaderCellDef>Nombre</th>
          <td mat-cell *matCellDef="let usuario">{{usuario.persona.nombre}}</td>
        </ng-container>

        <ng-container matColumnDef="apellido">
          <th mat-header-cell *matHeaderCellDef>Apellido</th>
          <td mat-cell *matCellDef="let usuario">{{usuario.persona.apellido}}</td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>Email</th>
          <td mat-cell *matCellDef="let usuario">{{usuario.email}} </td>
        </ng-container>

        <ng-container matColumnDef="nacimiento">
          <th mat-header-cell *matHeaderCellDef>Nacimiento</th>
          <td mat-cell *matCellDef="let usuario">
            {{usuario.persona.fecha_nacimiento | moment:'DD/MM/YYYY'}}
            ({{usuario.persona.fecha_nacimiento | edad}} años)
          </td>
        </ng-container>

        <ng-container matColumnDef="genero">
          <th mat-header-cell *matHeaderCellDef>Género</th>
          <td mat-cell *matCellDef="let usuario">{{usuario.persona.genero | constante:'genero'}}</td>
        </ng-container>

        <ng-container matColumnDef="peso">
          <th mat-header-cell *matHeaderCellDef>Peso</th>
          <td mat-cell *matCellDef="let usuario">
            <span *ngIf="usuario.perfil_epidemi?.peso">
              {{usuario.perfil_epidemi?.peso}} kg
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="fisica">
          <th mat-header-cell *matHeaderCellDef>Actividad física</th>
          <td mat-cell *matCellDef="let usuario">
            <span *ngIf="usuario.perfil_epidemi?.actividad_semanal">
              {{usuario.perfil_epidemi?.actividad_semanal}} por semana
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="lesionado">
          <th mat-header-cell *matHeaderCellDef>Tobillo lesionado</th>
          <td mat-cell *matCellDef="let usuario">
            {{usuario.perfil_epidemi?.tobillo_lesionado | constante:'tobillo'}} </td>
        </ng-container>
        <ng-container matColumnDef="evaluaciones">
          <th mat-header-cell *matHeaderCellDef>Evaluaciones</th>
          <td mat-cell *matCellDef="let usuario">
            <span class="principal-color">
              {{usuario.perfil_epidemi?.evaluaciones?.length}}</span> <!--  Numero de evaluaciones -->
            <br>
            {{usuario.perfil_epidemi?.evaluacion?.created_at | moment:'DD/MM/YYYY'}}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedUserColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedUserColumns;"></tr>
      </table>
      <div class="filtros  mt-4 mb-20">
        <mat-form-field appearance="outline" class="mr-20">
          <mat-label>Fecha de inicio </mat-label>
          <input matInput [matDatepicker]="inicio" [(ngModel)]="fecha_inicio">
          <mat-datepicker-toggle matSuffix [for]="inicio"></mat-datepicker-toggle>
          <mat-datepicker #inicio></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline" class="mr-20">
          <mat-label>Fecha de término</mat-label>
          <input matInput [matDatepicker]="termino" [(ngModel)]="fecha_termino">
          <mat-datepicker-toggle matSuffix [for]="termino"></mat-datepicker-toggle>
          <mat-datepicker #termino></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Estado evaluación</mat-label>
          <mat-select [(ngModel)]="estado">
            <mat-option [value]="null">Todas</mat-option>
            <mat-option value="C">Completadas</mat-option>
            <mat-option value="I">Incompletas</mat-option>
          </mat-select>
        </mat-form-field>

        <button mat-stroked-button color="primary" class="floar-r" (click)="evaluaciones()">Filtrar</button>
      </div>
      <!-- fin tabla datos usuario -->
      <app-loading [loading]="true" [style]="loading?'':'visibility:hidden'" [vistaLoading]="'normal'"></app-loading>
      <table #tb1 class="table-default mt-4" mat-table [dataSource]="dataEvaluaciones?.data">

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>ID</th>
          <td mat-cell *matCellDef="let evaluacion">{{evaluacion.id}}</td>
        </ng-container>

        <ng-container matColumnDef="fechaEvaluacion">
          <th mat-header-cell *matHeaderCellDef>Fecha evaluación</th>
          <td mat-cell *matCellDef="let evaluacion">{{evaluacion.created_at | moment:'DD/MM/YYYY'}}</td>
        </ng-container>

        <ng-container matColumnDef="lesionado">
          <th mat-header-cell *matHeaderCellDef>Tobillo lesionado</th>
          <td mat-cell *matCellDef="let evaluacion">
            {{evaluacion.perfil_epidemi.tobillo_lesionado | constante:'tobillo'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="estado">
          <th mat-header-cell *matHeaderCellDef>Estado</th>
          <td mat-cell *matCellDef="let evaluacion">
            <span *ngIf="evaluacion.resultado" class="e-completo">Completada</span>
            <span *ngIf="!evaluacion.resultado" class="e-incompleto">Incompleta</span>
            <!-- <span class="e-incompleto">{{evaluacion.estado}}</span> -->
          </td>
        </ng-container>

        <ng-container matColumnDef="evaluacionEncuesta">
          <th mat-header-cell *matHeaderCellDef>Evaluación por encuesta</th>
          <td mat-cell *matCellDef="let evaluacion">{{evaluacion.encuesta_evaluacion.resultado}}</td>
        </ng-container>

        <ng-container matColumnDef="pruebaFuerza">
          <th mat-header-cell *matHeaderCellDef>Fuerza</th>
          <td mat-cell *matCellDef="let evaluacion">
            <span *ngIf="evaluacion.item_fuerza">{{evaluacion.item_fuerza}}%</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="pruebaEquilibrio">
          <th mat-header-cell *matHeaderCellDef>Equilibrio</th>
          <td mat-cell *matCellDef="let evaluacion">
            <span *ngIf="evaluacion.item_equilibrio">{{evaluacion.item_equilibrio}}%</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="pruebaMovilidad">
          <th mat-header-cell *matHeaderCellDef>Movilidad</th>
          <td mat-cell *matCellDef="let evaluacion">
            <span *ngIf="evaluacion.item_movilidad">{{evaluacion.item_movilidad}}%</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="resultado">
          <th mat-header-cell *matHeaderCellDef>Resultado</th>
          <td mat-cell *matCellDef="let evaluacion">
            <span *ngIf="evaluacion.resultado" class="principal-color">{{evaluacion.resultado}}%</span>
            <!-- <br>
            {{evaluacion.resultado}} -->
          </td>
        </ng-container>
        <!-- Acciones -->
        <!-- <ng-container matColumnDef="acciones">
          <th class="text-right" mat-header-cell *matHeaderCellDef> Acciones </th>
          <td class="text-right" mat-cell *matCellDef="let element;let i = index">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item>
                <span>Otro</span>
              </button>
            </mat-menu>
          </td>
        </ng-container> -->

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator class="mat-paginator-sticky mat-elevation-z2" (page)="cambiarPagina($event)"
        [length]="dataEvaluaciones?.total" [pageSize]="paginacion.pageSize" [pageSizeOptions]="[10, 15, 20]">
      </mat-paginator>
    </div>
  </div>
</div>
