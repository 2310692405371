import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { routing } from "./app.routing";
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppGuard } from './app.guard';
import { AuthInterceptor } from './../services/api/auth.interceptor';
import { AuthService } from './../services/api/auth.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ResourceService } from 'src/services/api/resource.service';
import { EliPaginatorService } from 'src/services/app/paginator/eli-paginator.service';
import { MatPaginatorIntl } from '@angular/material/paginator';
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    routing,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSnackBarModule
  ],
  providers: [
    ResourceService,
    AuthService,
    AppGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: MatPaginatorIntl,
      useClass: EliPaginatorService,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
