import { RouterModule, Routes } from '@angular/router';
import { MenuAdminComponent } from './menu-admin/menu-admin.component';
// import { ConfigurarComponent }   from './configurar/configurar.component';
import { UsuariosModule } from './usuarios/usuarios.module';
import { ContratosModule } from './contratos/contratos.module';

// import { ProfesionalesModule } from './profesionales/profesionales.module';


const ADM_ROUTES: Routes = [

  {
    path: '', component: MenuAdminComponent, data: { title: "Menu admin - Kinetic" },
    children: [
      { path: 'usuarios', loadChildren: () => UsuariosModule, },
      { path: 'contratos', loadChildren: () => ContratosModule, },
      // { path: 'profesionales', loadChildren:()=> ProfesionalesModule,},

    ]
  }

];
export const routingAdmin = RouterModule.forChild(ADM_ROUTES);
