import { RouterModule, Routes } from '@angular/router';
import { IngresarComponent }   from './ingresar.component';
// import { IngresarGuard } from './ingresar.guard';
const INGRESAR_ROUTES: Routes = [
// canActivate:[IngresarGuard]
  { path: '', component: IngresarComponent,
    data: { title: "Acceso clientes - Saferplan", }
  }
  // { path: ':RUTA_CONS', component: IngresarComponent, data: { title: "SW CONSTRUCTORA - Control de costos para constructoras",},  canActivate:[IngresarGuard]}

];
export const routingIngresar = RouterModule.forChild(INGRESAR_ROUTES);
