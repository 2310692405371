<div class="top-content">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center">
          <h1>{{contrato ? 'Editar' : 'Agregar'}} Contrato</h1>
          <div class="ml-auto breadcrumbs ">
            <a mat-button color="accent" [routerLink]="['/admin/contratos']">
              Contratos
            </a>
            <span class="material-icons">keyboard_arrow_right</span>
            <a mat-button color="primary">
              {{contrato ? 'Editar' : 'Agregar'}} contrato
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container" style="margin-bottom:50px;">
  <form [formGroup]="contratoForm" class="row flex-colunm pt-4" (submit)="guardar()">
    <!-- <app-loading [loading]="loading" [vistaLoading]="'normal'"></app-loading> -->
    <!-- tabla datos usuario -->
    <mat-form-field appearance="outline" class="col-12 col-md-4">
      <mat-label>Nombre de contrato</mat-label>
      <input formControlName="nombre" matInput>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-12 col-md-4">
      <mat-label>Razón social</mat-label>
      <input formControlName="razon" matInput>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-12 col-md-4">
      <mat-label>Rut</mat-label>
      <input formControlName="rut" formatRut matInput>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-12 col-md-4">
      <mat-label>Nombre del contacto</mat-label>
      <input formControlName="contacto" matInput>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-12 col-md-4">
      <mat-label>Teléfono </mat-label>
      <input formControlName="telefono" matInput>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-12 col-md-4">
      <mat-label>Correo </mat-label>
      <input formControlName="correo" matInput>
    </mat-form-field>
    <div class="col-12">
      <button [disabled]="contratoForm.invalid || loading" mat-flat-button color="primary">{{contrato ? 'Editar' : 'Agregar'}}</button>
    </div>
  </form>
</div>