import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
// import { AuthService } from '../services/api/auth.service';

@Injectable()
export class AppGuard implements CanActivate {

  constructor(
    // private authService: AuthService,
    private router: Router) { }

  canActivate(): boolean {
    return true;
    // if (this.authService.isAuthenticated()) return true;
    // console.log('canActivate', false)
    // this.router.navigate(['/ingresar']);
    // return false;
  }

}
