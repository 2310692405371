import {Directive, ElementRef, EventEmitter, Output, HostListener } from '@angular/core';
import { NgControl } from "@angular/forms";

@Directive({
  selector: '[formatRut]',
  host: {
    '(blur)': 'onBlur($event)',
    '(focus)': 'onFocus($event)',
    '(input)': 'onChange($event)',
  },
})
export class RutDirective {
  @Output() public rutChange: EventEmitter<any>;

  constructor(private el: ElementRef, private control : NgControl) {
    this.rutChange = new EventEmitter();
  }

  @HostListener('input',['$event']) onEvent(ev: Event){
    let valueToTransform = this.el.nativeElement.value;
    // // do something with the valueToTransform
    this.control.control.setValue(this.rutFormat(valueToTransform));
    // let htmlInputElement: HTMLInputElement = <HTMLInputElement> ev.target;
    // htmlInputElement.value = this.rutFormat(htmlInputElement.value) || null;
  }

  public onFocus(ev: Event) {
    let htmlInputElement: HTMLInputElement = <HTMLInputElement> ev.target;
    htmlInputElement.value = this.rutFormat(htmlInputElement.value);
  }

  public onBlur(ev: Event) {
    let htmlInputElement: HTMLInputElement = <HTMLInputElement> ev.target;
    htmlInputElement.value = this.rutFormat(htmlInputElement.value) || '';
  }

  // @HostListener('input',['$event']) public onChange(ev: Event) {
  //   let htmlInputElement: HTMLInputElement = <HTMLInputElement> ev.target;
  //   this.rutChange.emit(this.rutFormat(htmlInputElement.value));
  // }

  private rutClean(value: string) {
    return typeof value === 'string' ? value.replace(/[^0-9kK]+/g, '').toUpperCase() : '';
  }

  private rutFormat(value: string) {
    const rut: string = this.rutClean(value);

    if (rut.length <= 1) {
      return rut;
    }

    let result: string = `${rut.slice(-4, -1)}-${rut.substr(rut.length - 1)}`;
    for (let i: number = 4; i < rut.length; i += 3) {
      result = `${rut.slice(-3 - i, -i)}.${result}`;
    }

    return result;
  }
  private rutValidate(value: string) {
  if (typeof value !== 'string') {
    return false;
  }

  const rut: string = this.rutClean(value);
    let rutDigits: number = parseInt(rut.slice(0, -1), 10);
    let m: number = 0;
    let s: number = 1;

    while (rutDigits > 0) {
      s = (s + rutDigits % 10 * (9 - m++ % 6)) % 11;
      rutDigits = Math.floor(rutDigits / 10);
    }
    const checkDigit: string = (s > 0) ? String((s - 1)) : 'K';

    return (checkDigit === rut.slice(-1));
  }
}
