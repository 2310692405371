import { RouterModule, Routes } from '@angular/router';
// import { AgregarUsuarioComponent }   from './agregar-usuario/agregar-usuario.component';
import { ListaUsuariosComponent } from './lista-usuarios/lista-usuarios.component';
import { EvaluacionesUsuarioComponent } from './evaluaciones-usuario/evaluaciones-usuario.component';
import { AgregarUsuarioComponent } from './agregar-usuario/agregar-usuario.component';

const USUARIOS_ADM_ROUTES: Routes = [
  { path: '', component: ListaUsuariosComponent, data: { title: "Lista usuarios - Tobifix " }, },
  { path: 'evaluaciones-usuario', component: EvaluacionesUsuarioComponent, data: { title: "Evaluaciones usuario - Tobifix " }, },
  { path: 'agregar-usuario', component: AgregarUsuarioComponent, data: { title: "Agregar usuario - Tobifix " }, },
  { path: 'editar-usuario', component: AgregarUsuarioComponent, data: { title: "Editar usuario - Tobifix " }, },
];
export const routingUsuarios = RouterModule.forChild(USUARIOS_ADM_ROUTES);
