import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit} from '@angular/core';
import {Validators,FormGroup ,FormBuilder,FormControl} from '@angular/forms';
import { Router,ActivatedRoute} from '@angular/router';
import { AuthService } from 'src/services/api/auth.service';
import { DataService } from 'src/services/app/data.service';
import { ValidacionService } from 'src/services/app/validacion/validacion.service';
// import { UsuarioService }    from "../../services/usuario.service";
@Component({
  selector: 'app-ingresar',
  templateUrl: './ingresar.component.html',
  styleUrls: ['./ingresar.component.scss']
})
export class IngresarComponent implements OnInit {
  ingresarForm:FormGroup;
  loadingIngresar:boolean = false;
  loadingOrga:boolean = false;
  loadingOlvidar:boolean = false;
  esOlvidado = false;
  esCodigo=false;
  type = 'password'
  email= new FormControl('', Validators.required);

  constructor( 
    private authServ: AuthService,
    private route: ActivatedRoute,
    private formBuilder:FormBuilder,
    private router: Router,
    private _snackbar: MatSnackBar
  ) {


 }
 ngOnInit() {
   this.ingresarForm = this.formBuilder.group({
       email: ['',Validators.required],
       password: ['', Validators.required ],
    });
 }

 olvidado(){
   this.esCodigo=true;
 }
 async ingresar() {
   this.loadingIngresar = true;
   this.authServ.login(this.ingresarForm.value)
     .then(
       result => {
         console.log('%c⧭ authServ.login result', 'color: #00e600', result);
         if (!result) {
           this.loadingIngresar = false;
           this._snackbar.open('Error inesperado', null, { duration: 3000 });
           return false;
         }
         DataService.logUser(result);
         this.authServ.ifLoggedIn() //esto ejecuta el router.navigate en app.component
         this.loadingIngresar = false;
         this.ingresarForm.reset();
       },
       error => {
         console.error(error)
         this.loadingIngresar = false;
         this._snackbar.open('Ocurrio un error durante el inicio de sesión', null, { duration: 3000 });
       }
     )
     .catch(error => {
       console.error(error)
       this.loadingIngresar = false;
       this._snackbar.open('Ocurrio un error durante el inicio de sesión', null, { duration: 3000 });
     })
 }


}
