import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, mapTo, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { DataService } from '../app/data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // route_api: string='http://localhost:8000/api';
  route_api: string = DataService.route_api;
  authState = new BehaviorSubject(false);

  constructor(private http: HttpClient) {
    this.ifLoggedIn(); // hace que el appcomponent te redirija al inicio o al login
  }

  register(data) {
    return this.http.post(this.route_api + 'auth/register', data, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
      .toPromise()
      .then(res => res as any)
      .catch(error => this.handleError(error));
  }
  login(credentials) {
    return this.http.post(this.route_api + 'auth/login_web', credentials, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
      .toPromise()
      .then(res => res as any)
      .catch(error => this.handleError(error));
  }
  logout() {
    return this.http.post(this.route_api + 'auth/logout', null, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
      .pipe(tap((result: any) => { this.doLogoutUser() })).toPromise();
  }
  me() {
    return this.http.post(this.route_api + 'auth/me', null, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
      .toPromise()
      .then(res => res as any)
      .catch(error => this.handleError(error));
  }

  refreshToken() {
    return this.http.post<any>(`${this.route_api}auth/refresh`, null, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }).pipe(tap((result: any) => {
      console.log('refreshToken()', result);
      DataService.logUser(result, false);
    }));
  }

  ifLoggedIn() {
    if (!DataService.usuario || !DataService.usuario.token) {
      this.authState.next(false);
    } else {
      return this.authState.next(true);
    }
  }

  doLogoutUser() {
    console.log('ENTRA AL LOGOUT')
    DataService.usuario = null;
    this.authState.next(null);
  }

  isAuthenticated(): boolean {
    console.log('isAuthenticated', this.authState.value)
    return this.authState.value;
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
