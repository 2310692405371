import { NgModule } from '@angular/core';
import { ConstantesPipe } from './contantes.pipe';
import { FilterPipe } from './filter.pipe';
import { EdadPipe, MomentPipe } from './moment.pipe';

@NgModule({
  imports: [
    // dep modules
  ],
  declarations: [
    FilterPipe,
    ConstantesPipe,
    MomentPipe,
    EdadPipe
  ],
  exports: [
    FilterPipe,
    ConstantesPipe,
    MomentPipe,
    EdadPipe
  ]
})
export class EliPipesModule { }