import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { routingIngresar } from "./ingresar.routing";
// import { BrowserModule } from '@angular/platform-browser';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// Angular Material Design
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule} from '@angular/material/input';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatSnackBarModule} from '@angular/material/snack-bar';
import { MatIconModule} from '@angular/material/icon';

// Componentes
//import { IngresarComponent }   from './ingresar/ingresar.component';
import { IngresarComponent }   from './ingresar.component';
// import { ValidacionModule } from '../../services/validacion/validacion.module';

// Proveedores y servicios
// import { UsuarioService } from "../../services/usuario.service";
import { LoadingModule } from  "../loading/loading.module";
// import { AuthService } from '../../services/api/auth.service';
// Proveedores
@NgModule({
  imports: [
    CommonModule,LoadingModule,
    FormsModule, ReactiveFormsModule,
    // Angular material design
    MatButtonModule,MatInputModule, MatCheckboxModule,MatSnackBarModule,MatIconModule,
    routingIngresar,

  ],

  declarations: [
    IngresarComponent,

  ],
  entryComponents: [],
  providers: [],
})

export class IngresarModule {

}
