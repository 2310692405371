import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { DataService } from '../app/data.service';
import { catchError, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { throwError, Observable, EMPTY } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  urlsToNotUse: Array<string>;
  refrescando = false;

  constructor(private _snackBar: MatSnackBar, private router: Router, private authService: AuthService) {
    this.urlsToNotUse = [
      '(?!auth/logout)(?!auth/refresh)(?!auth/me)(auth/.+)',
      //'usuario/cerrar-sesion/'
    ];
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //if (this.refrescando && !request.url.includes('auth/refresh')) return EMPTY;
    if (this.isValidRequestForInterceptor(request.url)) {
      if (!request.url.includes('auth/refresh')) {
        const diff = parseFloat(((DataService.usuario.expires_in - (new Date()).getTime()) / 60000).toFixed(2));
        if (diff <= 86400 / 2 && diff > 0 && !this.refrescando && !this.router.url.includes('ingresar')) {
          console.log('entra al refresh');
          console.log('Interceptor: ');
          console.log('Lifetime token', DataService.usuario.token);
          console.log('Timestamp actual', (new Date()).getTime());
          console.log('diference :', diff);
          this.refrescando = true;
          return this.authService.refreshToken().pipe(
            switchMap((result: any) => {
              this.refrescando = false;
              let accessToken = result.access_token;
              return this.errorHandler(accessToken, request, next);
            }), catchError((err) => {
              console.log('%c⧭ ERROR al refrescar TOKEN', 'color: #00736b', err);
              this.refrescando = false;
              return EMPTY;
            })
          );
        }
      } else {
        console.log('%c⧭ REFRESH TOKEN', 'color: #ac4970');
      }
      let accessToken = DataService.usuario.token;
      return this.errorHandler(accessToken, request, next);
    }
    return next.handle(request);
  }
  private errorHandler(accessToken, request: HttpRequest<any>, next: HttpHandler) {
    if (accessToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`
        }
      });
    }
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401) {
        this.authService.doLogoutUser();
        //this.navCtrl.navigateRoot(['/ingresar']);
        this._snackBar.open('Su sesión ha caducado, por favor vuelva a iniciar sesión', null, { duration: 3000, });
      } else if (err.status === 0 && err.error instanceof ProgressEvent) {
        this._snackBar.open('Hubo un error de conexión con el servidor', null, { duration: 3000, });
      } else if (err.error && err.error.toast) {
        this._snackBar.open(err.error.toast, null, { duration: 3000, });
      }
      const error = err;
      return throwError(error);
    }));
  }
  private isValidRequestForInterceptor(requestUrl: string): boolean {
    let positionIndicator: string = 'api/';
    let position = requestUrl.indexOf(positionIndicator);
    if (position > 0) {
      let destination: string = requestUrl.substr(position + positionIndicator.length);
      for (let address of this.urlsToNotUse) {
        if (new RegExp(address).test(destination)) {
          return false;
        }
      }
    }
    return true;
  }
}