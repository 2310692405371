<div class="top-content">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center">
          <h1>Contratos</h1>
          <div class="ml-auto">
            <a mat-stroked-button [routerLink]="['/admin/contratos/agregar-contrato']" [routerLinkActive]="['active']">
              <mat-icon matPrefix>add</mat-icon> Agregar Contrato
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container" style="margin-bottom:50px;">
  <div class="row">
    <div class="col-12">
      <form [formGroup]="filtroForm" (submit)="buscar()" class="filtros mt-4 mb-20">
        <mat-form-field appearance="outline" class="mr-20">
          <mat-label>Contrato</mat-label>
          <input formControlName="contacto" type=" text" placeholder="Seleccionar" aria-label="Number" matInput>
        </mat-form-field>
        <mat-form-field appearance="outline" class="mr-20">
          <mat-label>Correo</mat-label>
          <input formControlName="correo" matInput>
        </mat-form-field>
        <mat-form-field appearance="outline" class="mr-20">
          <mat-label>Nombre</mat-label>
          <input formControlName="nombre" matInput>
        </mat-form-field>
        <mat-form-field appearance="outline" class="mr-20">
          <mat-label>Estado</mat-label>
          <mat-select formControlName="estado">
            <mat-option value="T">
              Todos
            </mat-option>
            <mat-option [value]="true">
              Activos
            </mat-option>
            <mat-option [value]="false">
              Desactivados
            </mat-option>
          </mat-select>
        </mat-form-field>

        <button mat-stroked-button color="primary">Filtrar</button>
      </form>

      <app-loading [loading]="true" [style]="loading?'':'visibility:hidden'" [vistaLoading]="'normal'"></app-loading>
      <table mat-table [dataSource]="dataSource?.data" class="mat-elevation-z0 table-default mt-4">

        <!-- Nombre de usuario Column -->
        <ng-container matColumnDef="nombre_contrato">
          <th mat-header-cell *matHeaderCellDef> Contrato </th>
          <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
        </ng-container>

        <!-- Fecha de creación Column -->
        <ng-container matColumnDef="razon_social">
          <th mat-header-cell *matHeaderCellDef>Razón social</th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex flex-column contactos">
              {{element.razon}}
              <span>{{element.rut}}</span>
            </div>
          </td>
        </ng-container>

        <!-- Contrato Column -->
        <ng-container matColumnDef="nombre_contacto">
          <th mat-header-cell *matHeaderCellDef> Nombre de contacto </th>
          <td mat-cell *matCellDef="let element">
            {{element.contacto}}

          </td>
        </ng-container>


        <!-- Estado Column -->
        <ng-container matColumnDef="medios">
          <th mat-header-cell *matHeaderCellDef> Medios de Contacto </th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex flex-column contactos">
              <a [href]="'tel:' + element.telefono">{{element.telefono}}</a>
              <a [href]="'mailto:' + element.correo">{{element.correo}}</a>
            </div>

          </td>
        </ng-container>

        <!-- Estado Column -->
        <ng-container matColumnDef="num_usuarios">
          <th mat-header-cell *matHeaderCellDef> Número de usuarios </th>
          <td mat-cell *matCellDef="let element">
            {{element.usuarios_count}}
          </td>
        </ng-container>
        <!-- Estado Column -->
        <ng-container matColumnDef="estado">
          <th mat-header-cell *matHeaderCellDef>Estado </th>
          <td mat-cell *matCellDef="let element">
            <span [ngClass]="element.estado ? 'estado activo' : 'estado desactivado'">
              {{ element.estado ? 'Activado' : 'Desactivado' }}
            </span>
          </td>
        </ng-container>
        <!-- Acciones Column -->
        <ng-container matColumnDef="acciones">
          <th mat-header-cell *matHeaderCellDef> Acciones </th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button (click)="editar(element)" mat-menu-item>
                <span>Editar</span>
              </button>
              <button (click)="cambiarEstado(element)" mat-menu-item>
                <span>{{ element.estado ? 'Desactivar' : 'Activar' }}</span>
              </button>
              <button (click)="eliminar(element)" mat-menu-item>
                <span>Eliminar</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator class="mat-paginator-sticky mat-elevation-z0" (page)="cambiarPagina($event)"
        [pageSize]="filtroForm.value.por_pag" [pageSizeOptions]="[10, 15, 20]" [length]="dataSource?.total">
      </mat-paginator>
    </div>
  </div>
</div>