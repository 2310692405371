<div class="top-content">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center">
          <h1>Usuarios</h1>
          <div class="ml-auto">
            <a mat-stroked-button [routerLink]="['/admin/usuarios/agregar-usuario']" [routerLinkActive]="['active']">
              <mat-icon matPrefix>add</mat-icon> Agregar usuario
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container" style="margin-bottom:50px;">
  <div class="row">
    <div class="col-12">
      <form [formGroup]="filtroForm" class="filtros mt-4 mb-20">
        <mat-form-field appearance="outline" class="col-12 col-md-4">
          <mat-label>Contrato</mat-label>
          <input [formControl]="selectedContrato" type="text" placeholder="Seleccionar" aria-label="Number" matInput [matAutocomplete]="auto" >
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onContratoSelected($event.option.value)">
            <mat-option *ngFor="let contrato of contratosFiltrados | async" [value]="contrato">
              {{contrato.nombre}}
            </mat-option>
          </mat-autocomplete>
          <button *ngIf="selectedContrato.value" mat-icon-button matSuffix (click)="clear(1)">
            <mat-icon>clear</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field appearance="outline" class="mr-20">
          <mat-label>Rango de registro</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input formControlName="start" matStartDate formControlName="start" placeholder="Start date">
            <input formControlName="end" matEndDate formControlName="end" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <button *ngIf="filtroForm.controls.start.value" mat-icon-button matSuffix (click)="clear(2)">
            <mat-icon>clear</mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field appearance="outline" class="mr-20">
          <mat-label>Estado</mat-label>
          <mat-select formControlName="estado">
            <mat-option value="T">
              Todos
            </mat-option>
            <mat-option [value]="true">
              Activos
            </mat-option>
            <mat-option [value]="false">
              Desactivados
            </mat-option>
          </mat-select>
        </mat-form-field>

        <button mat-stroked-button color="primary" (click)="filtrar()">Filtrar</button>
      </form>

      <app-loading [loading]="true" [style]="loading?'':'visibility:hidden'" [vistaLoading]="'normal'"></app-loading>
      <table mat-table [dataSource]="dataSource?.data" class="mat-elevation-z0 table-default mt-4">

        <!-- Nombre de usuario Column -->
        <ng-container matColumnDef="nombre">
          <th mat-header-cell *matHeaderCellDef> Nombre de usuario </th>
          <td mat-cell *matCellDef="let element"> {{element.persona.nombre}} </td>
        </ng-container>

        <!-- Fecha de creación Column -->
        <ng-container matColumnDef="fecha">
          <th mat-header-cell *matHeaderCellDef> Fecha de registro </th>
          <td mat-cell *matCellDef="let element"> {{element.created_at | date:'dd/MM/yyyy HH:mm'}} </td>
        </ng-container>

        <!-- Correo Column -->
        <ng-container matColumnDef="correo">
          <th mat-header-cell *matHeaderCellDef> Correo </th>
          <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>

        <!-- Contrato Column -->
        <ng-container matColumnDef="contrato">
          <th mat-header-cell *matHeaderCellDef> Contrato </th>
          <td mat-cell *matCellDef="let element">
            {{element.contrato.nombre}}
          </td>
        </ng-container>

        <!-- Estado Column -->
        <ng-container matColumnDef="estado">
          <th mat-header-cell *matHeaderCellDef> Estado </th>
          <td mat-cell *matCellDef="let element">
            <span [ngClass]="element.estado ? 'estado activo' : 'estado desactivado'">
              {{ element.estado ? 'Activado' : 'Desactivado' }}
            </span>
          </td>
        </ng-container>

        <!-- Acciones Column -->
        <ng-container matColumnDef="acciones">
          <th mat-header-cell *matHeaderCellDef> Acciones </th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="editar(element)">
                <span>Editar</span>
              </button>
              <button (click)="cambiarEstado(element)" mat-menu-item>
                <span>{{ element.estado ? 'Desactivar' : 'Activar' }}</span>
              </button>
              <button (click)="eliminar(element)" mat-menu-item>
                <span>Eliminar</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator class="mat-paginator-sticky mat-elevation-z0" (page)="cambiarPagina($event)"
        [length]="dataSource?.total" [pageSize]="10" [pageSizeOptions]="[10, 15, 20]">
      </mat-paginator>
      
    </div>
  </div>
</div>