import { NgModule , CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from "./loading.component";
@NgModule({
  imports: [
    FormsModule,
    CommonModule,
   ],
  declarations: [
       LoadingComponent
  ],
  exports: [
      LoadingComponent
  ],

       schemas: [ CUSTOM_ELEMENTS_SCHEMA ]


})

export class LoadingModule {}
