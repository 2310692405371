import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResourceService } from '../../../../services/api/resource.service';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-agregar-usuario',
  templateUrl: './agregar-usuario.component.html',
  styleUrls: ['./agregar-usuario.component.scss'],

})
export class AgregarUsuarioComponent implements OnInit {

  usuarioForm: FormGroup;
  usuario: any = null;
  contratos: any = [];
  loading = false;
  selectedContrato: any;

  constructor(
    private router: Router,
    private resourceSrv: ResourceService,
    private snackBar: MatSnackBar, public dialog: MatDialog
  ) {
    this.load_form();
    this.load_contratos();
    let data = router.getCurrentNavigation().extras.state;
    console.log('data', data);
    if (data) {
      this.usuario = data.usuario;
      this.load_data();
    }

  }

  load_form() {
    this.usuarioForm = new FormBuilder().group({
      nombre: ['', Validators.required],
      email: ['', Validators.required],
      contrato_id: ['', Validators.required]
    });
  }

  load_data() {
    this.usuarioForm.patchValue({
      nombre: this.usuario.persona.nombre,
      email: this.usuario.email,
      contrato_id: this.usuario.contrato_id
    });
    this.selectedContrato = this.usuario.contrato;
  }
  
  async load_contratos() {
    const result = await this.resourceSrv.index('contrato');
    this.contratos = result;
    console.log('contratos', this.contratos);
  }

  ngOnInit() {
  }

  async guardar() {
    this.loading = true;
    const params = {
      persona: {
        nombre: this.usuarioForm.value.nombre
      },
      usuario: {
        email: this.usuarioForm.value.email,
        contrato_id: this.usuarioForm.value.contrato_id
      }
    }
    const result = await (this.usuario ? this.resourceSrv.update('usuario', this.usuario.id, params) :
      this.resourceSrv.store('usuario', params)).catch(error => {
        console.error(error); return { error: true, message: 'Ocurrio un error al guardar' }
      });
    this.snackBar.open(result.message, 'Cerrar', { duration: 4000 });
    if (!result.error) {
      this.router.navigate(['/admin/usuarios']);
    }
  }
  
  onContratoSelected(contrato: any): void {
    console.log('contrato', contrato);
    this.selectedContrato = contrato;
    this.usuarioForm.get('contrato_id')?.patchValue(contrato.id);
  }
}
