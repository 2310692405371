import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { routingUsuarios } from "./contratos.routing";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// Angular Material Design
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { LoadingModule } from "../../loading/loading.module";
import { MatDialogModule } from '@angular/material/dialog';


// import { AgregarUsuarioComponent }   from './agregar-usuario/agregar-usuario.component';
import { ListaContratosComponent } from './lista-contratos/lista-contratos.component';
import { ConfirmDialogComponent } from './lista-contratos/lista-contratos.component';
import { AgregarContratoComponent } from './agregar-contrato/agregar-contrato.component';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { EliPipesModule } from 'src/services/pipes/eli_pipes.module';
import { MatPaginatorModule } from '@angular/material/paginator';

// import { AdminService } from '../../../services/api/admin.service';
// import { ValidacionModule } from '../../../services/app/validacion/validacion.module';
// import { ModalConfirmarModule } from '../../modal-confirmar/modal-confirmar.module';
import { RutValidadorModule } from '../../../services/app/rut-validador/rut-validador.module';
@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    MatTabsModule,
    MatSnackBarModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatRadioModule,
    MatNativeDateModule,
    routingUsuarios,
    LoadingModule,
    // ValidacionModule,
    // ModalConfirmarModule,
    RutValidadorModule,
    EliPipesModule,
    MatPaginatorModule,
    MatDialogModule
  ],

  declarations: [
    // AgregarUsuarioComponent,
    ListaContratosComponent,
    AgregarContratoComponent,
    ConfirmDialogComponent
  ],
  entryComponents: [],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'es-CL' }
    // AdminService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class ContratosModule {

}
