import { RouterModule, Routes } from '@angular/router';
// import { AgregarUsuarioComponent }   from './agregar-usuario/agregar-usuario.component';
import { ListaContratosComponent } from './lista-contratos/lista-contratos.component';
import { AgregarContratoComponent } from './agregar-contrato/agregar-contrato.component';

const CONTRATOS_ADM_ROUTES: Routes = [
  { path: '', component: ListaContratosComponent, data: { title: "Lista de contratos - Tobifix " }, },
  { path: 'agregar-contrato', component: AgregarContratoComponent, data: { title: "Agregar contrato - Tobifix " }, },
  { path: 'editar-contrato', component: AgregarContratoComponent, data: { title: "Editar contrato - Tobifix " }, },
];
export const routingUsuarios = RouterModule.forChild(CONTRATOS_ADM_ROUTES);
