import { Component, OnInit, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/services/app/data.service';
// import {MatDialog} from '@angular/material';
import { AuthService } from '../../../services/api/auth.service';


@Component({
  selector: 'app-menu-admin',
  templateUrl: './menu-admin.component.html',
  styleUrls: ['./menu-admin.component.scss']
})
export class MenuAdminComponent {
  usuario = DataService.usuario;
  constructor(private router: Router,
    private authServ: AuthService
  ) { }
  async logout() {
    return await this.authServ.logout();
  }

}
