import { Component,  Input,OnChanges } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnChanges {
  @Input() loading: boolean = false;
  @Input() vistaLoading: string = "";
  // mensaje:string="Creando contrato";

  constructor() {


 }
 ngOnChanges() {
   // if(this.loading){
   //   setTimeout(()=>{
   //               this.mensaje = "Generando pagos";
   //    },1600);
   //    setTimeout(()=>{
   //                this.mensaje = "Preparando información";
   //     },3200);
   // }

 }


}
