import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResourceService } from '../../../../services/api/resource.service';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-agregar-contrato',
  templateUrl: './agregar-contrato.component.html',
  styleUrls: ['./agregar-contrato.component.scss'],

})
export class AgregarContratoComponent implements OnInit {

  contratoForm: FormGroup;
  loading = false;
  contrato: any = null;

  constructor(
    private router: Router,
    private resourceSrv: ResourceService,
    private snackBar: MatSnackBar, public dialog: MatDialog
  ) {
    this.load_form();
    let data = router.getCurrentNavigation().extras.state;
    console.log('data', data);
    if (data) {
      this.contrato = data.contrato;
      this.load_data();
    }
  }

  ngOnInit() { }

  load_form() {
    this.contratoForm = new FormBuilder().group({
      nombre: ['', Validators.required],
      razon: ['', Validators.required],
      rut: ['', Validators.required],
      contacto: ['', Validators.required],
      telefono: ['', Validators.required],
      correo: ['', Validators.required]
    });
  }

  load_data() {
    this.contratoForm.patchValue({
      nombre: this.contrato.nombre,
      razon: this.contrato.razon,
      rut: this.contrato.rut,
      contacto: this.contrato.contacto,
      telefono: this.contrato.telefono,
      correo: this.contrato.correo,
    });
  }

  async guardar() {
    this.loading = true;
    const result = await (this.contrato ? this.resourceSrv.update('contrato', this.contrato.id, this.contratoForm.value) : 
      this.resourceSrv.store('contrato', this.contratoForm.value)).catch(error => { 
        console.error(error); return { error: true, message: 'Ocurrio un error al guardar' } 
      });
    this.snackBar.open(result.message, 'Cerrar', { duration: 4000 });
    if (!result.error) {
      this.router.navigate(['/admin/contratos']);
    }
    this.loading = false;
  }

}
