import { Component, OnInit, ViewChild } from '@angular/core';
// import { AdminService } from '../../../../services/api/admin.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable } from '@angular/material/table';
// import { ModalConfirmarComponent } from '../../../modal-confirmar/modal-confirmar.component';
import { ResourceService } from '../../../../services/api/resource.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-evaluaciones-usuario',
  templateUrl: './evaluaciones-usuario.component.html',
  styleUrls: ['./evaluaciones-usuario.component.scss'],

})
export class EvaluacionesUsuarioComponent implements OnInit {
  displayedColumns: string[] = ['id', 'fechaEvaluacion', 'lesionado', 'estado', 'pruebaFuerza', 'pruebaEquilibrio', 'pruebaMovilidad', 'resultado'];//, 'acciones'];
  dataEvaluaciones: any;
  //[{ id:'1', fechaEvaluacion:'10/10/2020', lesionado:'Izquierdo',estado:'Completado', evaluacionEncuesta:'F10% - E39% - M30%',pruebaFuerza:'F55%',PruebaEquilibrio:'E35%', PruebaMovilidad:'M30%', resultado:'F10% - E39% - M30%',evaluaciones:'2 220/10/2020'}];

  displayedUserColumns: string[] = ['id', 'nombre', 'apellido', 'email', 'nacimiento', 'genero', 'peso', 'fisica', 'lesionado', 'evaluaciones'];
  usuario = [];//[{
  //id: '1', nombre: 'Mirko', apellido: 'Hernandéz', email: 'mirko@gmail.com', nacimiento: '10/12/1992 (28 años)', genero: 'Masculino', peso: '77 kg', fisica: '2 por semana', lesionado: 'Izquierdo',
  //evaluaciones: ' 2'
  //}];
  loading = false;
  fecha_inicio;
  fecha_termino;
  estado;
  paginacion = { length: 0, pageSize: 10, pageIndex: 0 };

  @ViewChild('tb1') table_1: MatTable<any>;

  constructor(
    private router: Router,
    private resourceSrv: ResourceService,
    private snackBar: MatSnackBar, public dialog: MatDialog,) {
    console.log("tiene extras", this.router.getCurrentNavigation().extras.state);
    this.usuario.push(this.router.getCurrentNavigation().extras.state.usuario);
  }
  cambiarPagina(e) {
    console.log('cambiarPagina', e)
    this.paginacion = e;
    this.evaluaciones();
  }

  ngOnInit() {
    this.evaluaciones();
  }

  evaluaciones() {
    this.loading = true;
    var params = {
      fecha_inicio: this.fecha_inicio,
      fecha_termino: this.fecha_termino,
      estado: this.estado,
      page: this.paginacion.pageIndex + 1,
      per_page: this.paginacion.pageSize
    }
    this.resourceSrv.post(`usuario/${this.usuario[0]?.id}/evaluaciones`, params).then(r => {
      console.log("resultado", r);
      this.dataEvaluaciones = r;
      this.loading = false;
    }).catch(e => {
      this.loading = false;
      console.log("Se ha producido un error al listar usuarios", e);
    })
  }


}
