import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { routingAdmin } from "./admin.routing";
import { MenuAdminComponent } from './menu-admin/menu-admin.component';
// Angular Material Design
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';

// import { ResourceService } from '../../services/api/resource.service';
@NgModule({
  declarations: [MenuAdminComponent],
  imports: [
    CommonModule,
    routingAdmin,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    MatCheckboxModule,
    MatDialogModule,
  ],
  providers: [
    // ResourceService
  ]
})
export class AdminModule { }
